.App {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (min-width: 650px ) {
  .App {
    height: 100vh;
    background-position: center center;
  }
}